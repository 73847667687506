import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import React, { useEffect, useState }  from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { addDoc, getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";
import { client,  mqttConnect, mqttSub, mqttUnSub, mqttPublish, setNewEvent} from './mqtt.mjs';
import Geohash from 'latlon-geohash';

import '../App.css';

function App() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [uid, setUid] = useState('');
  const [event, setEvent] = useState('');
  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);

  const handleLogout = () => {
      signOut(auth).then(() => {
        navigate("/");
        console.log("Signed out successfully")
      }).catch((error) => {
        console.log(error);
      });
  }

  async function getLocations(uid){

    const q = query(collection(db, "adverts"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);

    if(querySnapshot.size > 0){
      // setUsers(querySnapshot.docs);
      let locationList = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        locationList.push(doc.data());
      });
      setLocations(locationList);
    } else {
      setLocations([]);
    }

  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
          setUid(user.uid);
          setName(user.displayName);
          mqttConnect(user.uid);
          getLocations(user.uid);
          // checkPushEnabled(user);
        } else {
          console.log("user is logged out")
          navigate("/")
        }
      });

      // if ("serviceWorker" in navigator) {
      //   navigator.serviceWorker
      //     .register("/sw.js", { scope: "/" })
      //     .then((registration) => {
      //       console.log("Service worker registration succeeded.");
      //     })
      //     .catch((error) => {
      //       console.error(`Service worker registration failed with ${error}`);
      //     });
      // }
  }, []);

  const addLocation = async () => {
    let locationName = document.getElementById("locationName").value
    let locationStreet = document.getElementById("locationStreet").value
    let locationCity = document.getElementById("locationCity").value
    let locationState = document.getElementById("locationState").value
    let locationZip = document.getElementById("locationZip").value
    let locationDeal = document.getElementById("locationDeal").value

    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${locationStreet},+${locationCity},+${locationState}&key=AIzaSyAPgBWa-TfocjVl_p0k6RgfjWHXhxO8j44`

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data.results[0].geometry.location.lat);
        // console.log(data.results[0].geometry.location.lng);
        let lat = data.results[0].geometry.location.lat;
        let lng = data.results[0].geometry.location.lng;
        let geohash = Geohash.encode(lat, lng, 12);

        setDoc(doc(db, "adverts", geohash), {
          name: locationName,
          street: locationStreet,
          city: locationCity,
          state: locationState,
          zip: locationZip,
          deal: locationDeal,
          lat: lat,
          lng: lng,
          geohash: geohash,
          uid: uid,
        }, { merge: true });
        getLocations(uid)

        // addDoc(collection(db, "adverts"), {
        //   name: locationName,
        //   street: locationStreet,
        //   city: locationCity,
        //   state: locationState,
        //   zip: locationZip,
        //   lat: lat,
        //   lng: lng,
        //   geohash: geohash,
        //   uid: uid,
        // }, { merge: true });

      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const deleteLocation = async (name) => {
    console.log(name);
    await deleteDoc(doc(db, "adverts", name));
    getLocations(uid)
  }

  return (
    <>
    <Navbar bg="dark" expand="lg">
      <Container>
        <Navbar.Brand href="#home"><i className="fa-solid fa-hand-peace"></i> CONVOY.IM</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Link href="/Main">Messaging</Nav.Link>
            <Nav.Link href="/Adverts">Advertising</Nav.Link>
            <Nav.Link href="/About">About</Nav.Link>
            <Nav.Link onClick={handleLogout}>Sign Out</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Container>
      <Form>
        <Form.Control type="text" placeholder="Location Name" id="locationName"/>
        <Form.Control type="text" placeholder="Street Address" id="locationStreet"/>
        <Form.Control type="text" placeholder="City" id="locationCity"/>
        <Form.Select aria-label="State" id="locationState">
          <option>State</option>
          <option value="AL">Alabama</option>
        	<option value="AK">Alaska</option>
        	<option value="AZ">Arizona</option>
        	<option value="AR">Arkansas</option>
        	<option value="CA">California</option>
        	<option value="CO">Colorado</option>
        	<option value="CT">Connecticut</option>
        	<option value="DE">Delaware</option>
        	<option value="DC">District Of Columbia</option>
        	<option value="FL">Florida</option>
        	<option value="GA">Georgia</option>
        	<option value="HI">Hawaii</option>
        	<option value="ID">Idaho</option>
        	<option value="IL">Illinois</option>
        	<option value="IN">Indiana</option>
        	<option value="IA">Iowa</option>
        	<option value="KS">Kansas</option>
        	<option value="KY">Kentucky</option>
        	<option value="LA">Louisiana</option>
        	<option value="ME">Maine</option>
        	<option value="MD">Maryland</option>
        	<option value="MA">Massachusetts</option>
        	<option value="MI">Michigan</option>
        	<option value="MN">Minnesota</option>
        	<option value="MS">Mississippi</option>
        	<option value="MO">Missouri</option>
        	<option value="MT">Montana</option>
        	<option value="NE">Nebraska</option>
        	<option value="NV">Nevada</option>
        	<option value="NH">New Hampshire</option>
        	<option value="NJ">New Jersey</option>
        	<option value="NM">New Mexico</option>
        	<option value="NY">New York</option>
        	<option value="NC">North Carolina</option>
        	<option value="ND">North Dakota</option>
        	<option value="OH">Ohio</option>
        	<option value="OK">Oklahoma</option>
        	<option value="OR">Oregon</option>
        	<option value="PA">Pennsylvania</option>
        	<option value="RI">Rhode Island</option>
        	<option value="SC">South Carolina</option>
        	<option value="SD">South Dakota</option>
        	<option value="TN">Tennessee</option>
        	<option value="TX">Texas</option>
        	<option value="UT">Utah</option>
        	<option value="VT">Vermont</option>
        	<option value="VA">Virginia</option>
        	<option value="WA">Washington</option>
        	<option value="WV">West Virginia</option>
        	<option value="WI">Wisconsin</option>
        	<option value="WY">Wyoming</option>
        </Form.Select>
        <Form.Control type="text" placeholder="Zipcode" id="locationZip"/>
        <Form.Control as="textarea" rows={3} placeholder="Deal Description" id="locationDeal"/>
      </Form>
      <Button variant="dark" onClick={() => addLocation()}>Add</Button>
      <br/><br/>
      <div>
      <table className="table">
      <thead>
          <tr>
              <th>Name</th><th>Address</th><th>Deal</th><th>Actions</th>
          </tr>
      </thead>
      <tbody>
      {locations.map((location, index) => (
              <tr key={'tr1-'+index}>
                <td key={'td1-'+index}>{location.name} </td><td key={'td2-'+index}>{location.street}, {location.city} {location.state}, {location.zip}</td>
                <td key={'td3-'+index}>{location.deal}</td>
                <td key={'td4-'+index}><Button variant="danger" onClick={() => deleteLocation(location.geohash)}>Delete</Button></td>
              </tr>
          ))}
      </tbody>
      </table>
      </div>
    </Container>
    </>
  );
}

export default App;
