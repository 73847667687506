import React, { useEffect } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import '../App.css';

const Home = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              const uid = user.uid;
              // ...
              console.log("uid", uid)
              console.log(user);
              navigate("/main")
            } else {
              // User is signed out
              // ...
              console.log("user is logged out")
            }
          });
    }, [])

  return (
    <>
    <Container className="text-black" >

      <div
        className="bg-image card shadow-1-strong img-fluid"
        style={{backgroundImage: `url("/convoy-cover-ridenow.png")`, maxWidth: "100%", height: "100vh", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}
      >
        <div className="card-body text-black">
          <h1 className="card-title justify-content"><i className="fa-solid fa-hand-peace"></i> CONVOY.IM</h1>
          <h5 className="card-text ">
            Simple convoy communications
          </h5>
          <p>
            Convoy communications app for motorcycles, side-by-sides, jeeps, etc. includes one-touch messaging, maps, directions, weather alerts, and voice!
          </p>
        </div>
        <footer className="text-center text-lg-start">
          <div className="text-center p-3">
          <div>
          <NavLink to="/signup" className="btn btn-primary btn-lg justify-content-center">
              Get Started
          </NavLink>
          <br/><br/>
          <NavLink to="/login" className="btn btn-primary btn-lg justify-content-center">
              Log In
          </NavLink>
          </div>
          </div>
        </footer>
        <br/>
      </div>
    </Container>
    </>
  )
}

export default Home
