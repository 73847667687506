import mapboxgl from 'mapbox-gl';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from '../firebase';
import { getDoc, doc } from "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../App.css';

const Map = () => {

    const navigate = useNavigate();
    mapboxgl.accessToken = 'pk.eyJ1IjoiY21hdHRoaWV1IiwiYSI6ImNsZ3Bxd3kyZjEyOTMzZmpxOTc4YzNqYTYifQ.ae3hPq6JTGT8mVZlKtRLlw';

    const mapContainer = useRef(null);
    const map = useRef(null);

    async function getUserSettings(uid){
      let eventCode, vehicle;
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      if(docSnap.exists()) {
        if(docSnap.data().event){
          eventCode = docSnap.data().event;
        }
        if(docSnap.data().vehicle){
          vehicle = docSnap.data().vehicle;
        }
        console.log(eventCode, vehicle);

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function(position) {
              console.log(position);
              if (map.current) return; // initialize map only once
              map.current = new mapboxgl.Map({
              container: mapContainer.current,
              style: 'mapbox://styles/mapbox/satellite-streets-v12',
              center: [position.coords.longitude, position.coords.latitude],
              zoom: 12
              });

              var apiUrl = `https://comvoy-api.herokuapp.com/findplayers?condition={"vehicle":"${vehicle}", "event":"${eventCode}"}`
              fetch(apiUrl)
                .then((response) => response.json())
                .then((fbData) => {
                  console.log("players", fbData)
                  fbData.forEach(function (arrayItem) {
                    const popup = new mapboxgl.Popup({ offset: 25 }).setText(arrayItem.name);
                    const marker = new mapboxgl.Marker()
                      .setLngLat([arrayItem.pos.lng, arrayItem.pos.lat])
                      .addTo(map.current)
                      .setPopup(popup);
                  });
                })
          });
        }
      }
    }

    useEffect(() => {

      onAuthStateChanged(auth, (user) => {
        if (user) {
          getUserSettings(user.uid)
        } else {
          navigate("/")
        }
      });

    }, []);


  return (
    <>
      <NavLink to="/"><i className="fa-solid fa-arrow-left"> BACK</i></NavLink>
      <div ref={mapContainer} className="map-container" />
    </>
  )
}

export default Map
