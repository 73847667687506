import mqtt from "precompiled-mqtt";
import Geohash from 'latlon-geohash';
import Swal from 'sweetalert2';
import { db } from '../firebase.js';
import { getDoc, doc } from "firebase/firestore";
import { speak } from './speech.mjs';

// export const geolevel = 6; //1.22km https://docs.quadrant.io/quadrant-geohash-algorithm
// export const listenRadius = 1.22; //kilometers
export let client;
const mqttOptions = { qos: 2 };
let eventCode = '';
let vehicle;

async function getUserSettings(uid){
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);
  if(docSnap.exists()) {
    if(docSnap.data().event){
      eventCode = docSnap.data().event;
    }
    if(docSnap.data().vehicle){
      vehicle = docSnap.data().vehicle;
    }
  }
}

export const mqttConnect = (uid) => {
  console.log("CONNECT", uid);

  getUserSettings(uid);

  // client = mqtt.connect("wss://comvoy.cedalo.cloud:443/mqtt", {
  //   username: "comvoy",
  //   password: "c0mv0y",
  //   clientID: uid,
  //   keepAlive: 65535,
  //   cleanSession: false
  // });

  client = mqtt.connect("wss://03k1n0.stackhero-network.com/", {
    username: "comvoy",
    password: "5ttkfZalEQUTUI6prwkUl2WgJ25WIg1G",
    clientID: uid,
    keepAlive: 65535,
    cleanSession: false
  });

  console.log("MQTT CLIENT", client)
  client.on('connect', () => {
    console.log("connected mqtt");
  });
  client.on('error', (err) => {
    console.error('Connection error: ', err);
    client.end();
  });
  client.on('reconnect', () => {
    console.log("RECONNECT");
    // Does forcefully connecting help iOS?
    // client = mqtt.connect("wss://comvoy.cedalo.cloud:443/mqtt", {
    //   username: "comvoy",
    //   password: "c0mv0y",
    //   clientID: uid,
    //   keepAlive: 65535,
    //   cleanSession: false
    // });

    client = mqtt.connect("wss://03k1n0.stackhero-network.com/", {
      username: "comvoy",
      password: "5ttkfZalEQUTUI6prwkUl2WgJ25WIg1G",
      clientID: uid,
      keepAlive: 65535,
      cleanSession: false
    });

  });
  client.on('message', (topic, data) => {
    if(topic.indexOf("answer") === -1 && topic.indexOf("offer") === -1) {
      data = JSON.parse(data.toString())
      var messageParts = data.message.split(":");

      // map link detected
      if (data.message.indexOf("map~",0) > -1 && data.vehicle === vehicle && data.event === eventCode){
        var mapArray = data.message.split("~");
        Swal.fire({
          title: mapArray[1],
          html: `<i class="fa-solid fa-map-location-dot fa-10x"></i><br/><br/><h1><a href="${mapArray[2]}" target="_new">link</a></h1>`,
          timer: 5000,
          confirmButtonColor: "#000",
          customClass: 'swal-wide',
        })

      } else if(data.vehicle === vehicle && data.event === eventCode){
        if(document.getElementById("tbg-radio-2").checked === true){ //sound
          speak(messageParts[0] + " says " + messageParts[1]);
        }

        Swal.fire({
          title: messageParts[0],
          html: `<i class="fa-sharp fa-solid ${data.icon} fa-10x"></i><br/><br/><h1>${messageParts[1]}</h1>`,
          timer: 5000,
          confirmButtonColor: "#000",
          customClass: 'swal-wide',
        })

      }
    }
  });
}

export const mqttSub = (topic) => {
  if (client) {
    client.subscribe(topic, (error) => {
      if (error) {
        console.log('Subscribe to topics error', error)
        return
      } else {
        // console.log("Subscribed to:", topic);
      }
    });
  }
};

export const mqttUnSub = (topic) => {
  if (client) {
    console.log("UNSUBSCRIBE TOPIC:", topic);
    client.unsubscribe(topic, error => {
      if (error) {
        console.log('Unsubscribe error', error)
        return
      } else {
        // console.log("Unsubscribed from:", topic);
      }
    });
  }
};

export const mqttPublish = (topic, payload, uid, users) => {
  var apiUrl;
  if (client) {
    console.log("MQTT PUBLISH", topic, payload, mqttOptions);
    client.publish(topic, payload, mqttOptions, error => {
      if (error) {
        console.log('Publish error: ', error);
      } else {
        // Send push notifications
        if (topic !== "location" && topic !== "event"){

          var data = JSON.parse(payload);
          console.log("publishpayloaddata", data);

          const fetchOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: `event=${topic}&message=${data.message}`
          }
          // fetch("http://localhost:3001/yo", fetchOptions)
          fetch("https://comvoy-api.herokuapp.com/push", fetchOptions)
          .then((response) => {
            // return response.json();
          })
          .catch((error) => {
            console.log(error);
          })


          // var data = JSON.parse(payload);
          // console.log("publishpayloaddata", data);
          //
          // if (users){
          //
          //   if(users.length>0){
          //     users.forEach(function (arrayItem) {
          //       if(arrayItem.uid && arrayItem.uid !== uid){
          //         sendPush(arrayItem, data.message);
          //       }
          //     });
          //   }
          //
          // } else {
          //
          //   // apiUrl = `https://comvoy-api.herokuapp.com/findplayers?condition={"event":${eventCode}}`
          //   apiUrl = `https://comvoy-api.herokuapp.com/findplayers?condition={"event":"${data.event}"}`
          //   fetch(apiUrl)
          //     .then((response) => response.json())
          //     .then((fbData) => {
          //       console.log("findplayers", fbData)
          //       if(fbData.length>0){
          //         fbData.forEach(function (arrayItem) {
          //           if(arrayItem.uid && arrayItem.uid !== uid){
          //             sendPush(arrayItem, data.message);
          //           }
          //         });
          //       }
          //     })
          //
          // }

        }
      }
    });
  }
}

export const mqttDisconnect = () => {
  if (client) {
    client.end(() => {
      console.log("MQTT disconnected");
    });
  }
}

export function setNewEvent(newEvent){
  eventCode = newEvent;
}

// // Can use this function below OR use Expo's Push Notification Tool from: https://expo.dev/notifications
// async function sendPushNotification(user, message){
// // sendPushNotification = async (user, message) => {
//   console.log("PUSH", user, message);
//
//   if(user.uid === '') return
//   console.log("PUSH", user, message);
//   const docRef = doc(db, "users", user.uid);
//   try {
//       const docSnap = await getDoc(docRef);
//       // if(docSnap.exists() && docSnap.data().pushNotification) {
//       if(docSnap.exists()) {
//
//         const messageObg = {
//           to: docSnap.data().fcmToken,
//           sound: 'default',
//           title: 'Convoy',
//           body: message,
//           data: { someData: 'goes here' },
//           icon: "https://convoy.im/logo192.png",
//         };
//
//         var pushReceipt = await fetch('https://exp.host/--/api/v2/push/send', {
//           method: 'POST',
//           headers: {
//             Accept: 'application/json',
//             'Accept-encoding': 'gzip, deflate',
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(messageObg),
//         });
//         // console.log(JSON.stringify(pushReceipt));
//
//       }
//   } catch(error){
//     console.log(error);
//   }
//
// }


async function sendPush(user, message){
  // lookup uid in firebase to get fcm token
  console.log("push msg:", message, user);
  if(user.uid === '') return

  // const docRef = doc(db, "users", user.uid);
  try {
      // const docSnap = await getDoc(docRef);
      // if(docSnap.exists()) {
          // console.log(docSnap.data());
          // console.log("sendPushNotification", user.uid, docSnap.data().fcmToken, message);
          const notifyData = {
            "notification": {
                "title": "CONVOY.IM",
                "body": message,
                "click_action": "https://convoy.im",
                "icon": "https://convoy.im/logo192.png"
            },
            "to": user.token
          }

          fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": "key=AAAALV-cnTs:APA91bH2ZqSdv_k-E3pwBPtgh-zZxa4CiWnSR9_1HEFGjfnR8rY3GXT5LqnRvDcg6Hg5BfTnv4U2-Ks7mYnmvhmp1QRP5VD6bt0DMMK3tgeC-GuN-V3GaOog08_A5di0Q0i8n0YmFVt0"
            },
            body: JSON.stringify(notifyData),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log('Success:', data);
            })
            .catch((error) => {
              console.error('Error:', error);
            });

      // } else {
      //     console.log("Document does not exist")
      // }

  } catch(error) {
      console.log(error)
  }
}
