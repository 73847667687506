import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import React, { useEffect, useState }  from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { getDoc, getDocs, doc, setDoc, deleteDoc, updateDoc, deleteField, query, collection, where } from "firebase/firestore";
import { client,  mqttConnect, mqttSub, mqttUnSub, mqttPublish, setNewEvent} from './mqtt.mjs';

import '../App.css';

function App() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [uid, setUid] = useState('');
  const [event, setEvent] = useState('');
  const [users, setUsers] = useState([]);

  const handleLogout = () => {
      signOut(auth).then(() => {
        navigate("/");
        console.log("Signed out successfully")
      }).catch((error) => {
        console.log(error);
      });
  }

  async function getUserSettings(uid){
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if(docSnap.exists()) {

      // if(docSnap.data().level){
      //
      // }

    } else {
      // navigate('/vehicle');
    }
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
          setUid(user.uid);
          setName(user.displayName);
          mqttConnect(user.uid);
          getUserSettings(user.uid);
          // checkPushEnabled(user);
        } else {
          console.log("user is logged out")
          navigate("/")
        }
      });

      // if ("serviceWorker" in navigator) {
      //   navigator.serviceWorker
      //     .register("/sw.js", { scope: "/" })
      //     .then((registration) => {
      //       console.log("Service worker registration succeeded.");
      //     })
      //     .catch((error) => {
      //       console.error(`Service worker registration failed with ${error}`);
      //     });
      // }
  }, []);


  const handleEventChange = async (event) => {
    setEvent(event.target.value);

    const q = query(collection(db, "users"), where("event", "==", event.target.value));
    const querySnapshot = await getDocs(q);

    if(querySnapshot.size > 0){
      // setUsers(querySnapshot.docs);
      let userList = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        userList.push({uid: doc.id, token: doc.data().fcmToken});
      });
      setUsers(userList);
    } else {
      setUsers([]);
    }

  }

  const sendMessage = () => {
    // push mqtt message and send notification
    let message = document.getElementById("messageText").value
    // console.log(message, event, auth.currentUser.displayName, uid);

    if(event === "all"){
      const fetchOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: `message=${message}`
      }
      // fetch("http://localhost:3001/yo", fetchOptions)
      fetch("https://comvoy-api.herokuapp.com/pushall", fetchOptions)
      .then((response) => {
        console.log(response);
        // return response.json();
      })
      .catch((error) => {
        console.log(error);
      })
    } else {
      var data = {
        clientId: uid,
  			message: auth.currentUser.displayName + ": " + message,
        icon: "faMessage",
        event: event,
        color: "blue",
  		};
      mqttPublish(event, JSON.stringify(data), uid, users);
    }
  }

  return (
    <>
    <Navbar bg="dark" expand="lg">
      <Container>
        <Navbar.Brand href="#home"><i className="fa-solid fa-hand-peace"></i> CONVOY.IM</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Link href="/Main">Messaging</Nav.Link>
            <Nav.Link href="/Adverts">Advertising</Nav.Link>
            <Nav.Link href="/About">About</Nav.Link>
            <Nav.Link onClick={handleLogout}>Sign Out</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Container>
      <Form.Control size="lg" type="text" placeholder="Enter Event Code" value={event} onChange={handleEventChange}/>
      <br/>
      <Form.Group className="mb-3">
        <Form.Label>Send Group Message to {users.length} Users</Form.Label>
        <Form.Control as="textarea" rows={3} id="messageText"/>
      </Form.Group>
      <Button variant="dark" onClick={() => sendMessage()}>Send</Button>
    </Container>
    </>
  );
}

export default App;
