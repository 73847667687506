import React, {useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  // sendEmailVerification,
  updateProfile  } from 'firebase/auth';
import { auth } from '../firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import '../App.css';

const Signup = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const onSubmit = async (e) => {
      e.preventDefault()

      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            updateProfile(auth.currentUser, { displayName: firstName + " " + lastName }).catch(
              (err) => console.log(err)
            );
            navigate("/vehicle")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            // ..
        });

        // await sendEmailVerification(auth.currentUser).catch((err) =>
        //   console.log(err)
        // );
    }



  return (
    <>
    <Container className="text-black" style={{height: "100vh"}}>
      <div
        className="bg-image card shadow-1-strong img-fluid"
        style={{backgroundImage: `url("/IMG_4698.jpeg")`, maxWidth: "100%", height: "100vh", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}
      >
        <div className="card-body text-black">
          <h1 className="card-title justify-content-center"><i className="fa-solid fa-hand-peace"></i> CONVOY.IM</h1>
          <h5 className="card-text justify-content-center">
            Convoy Instant Messaging
          </h5>
          <br/>
          <div>

            <form onSubmit={onSubmit} className="mt-8 space-y-10" >
                <div className=" space-y-10 rounded-md shadow-sm">
                    <div>
                        <input
                            label="First name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            name="firstname"
                            type="text"
                            required
                            className="form-control form-control-lg"
                            placeholder="First name"
                        />
                    </div>

                    <div>
                        <input
                            label="Last name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            type="text"
                            name="lastname"
                            className="form-control form-control-lg"
                            placeholder="Last name"
                        />
                    </div>

                    <div>
                        <input
                            type="email"
                            label="Email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="form-control form-control-lg"
                            placeholder="Email address"
                        />
                    </div>

                    <div>
                        <input
                            type="password"
                            label="Create password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="form-control form-control-lg"
                            placeholder="Password"
                        />
                    </div>
                </div>
                <br/>
                <div>
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                    >
                        Sign up
                    </button>
                </div>

            </form>

            <br/>
            <p className="text-sm text-white text-center">
                Already have an account?{' '}
                <NavLink to="/login" className="underline text-tertiary">
                    Sign in
                </NavLink>
            </p>

          </div>
        </div>
      </div>

    </Container>
    </>
  )
}

export default Signup
