import EasySpeech from 'easy-speech'

EasySpeech.init({ maxTimeout: 5000, interval: 250 })
    .then(() => console.log('tts load complete'))
    .catch(e => console.error(e))

export function speak(textToSay) {
  const voice = EasySpeech.voices()[0]
  EasySpeech.speak({
    text: textToSay,
    voice: voice, // optional, will use a default or fallback
    pitch: 1,
    rate: 1,
    volume: 1,
    boundary: event => console.debug('word boundary reached', event.charIndex),
    error: e => console.log(e)
  })
}
