import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import '../App.css';

const About = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
      signOut(auth).then(() => {
        navigate("/");
        console.log("Signed out successfully")
      }).catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
    <Navbar bg="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/main"><i className="fa-solid fa-hand-peace"></i> CONVOY.IM</Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Link href="/Main">Messaging</Nav.Link>
            <Nav.Link href="/Adverts">Advertising</Nav.Link>
            <Nav.Link href="/About">About</Nav.Link>
            <Nav.Link onClick={handleLogout}>Sign Out</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Container>
      <div className="row">
      <Card>
        <Card.Title className="card-body text-black">
          Convoy.im is a location-based group communications app ideal for convoys of motorcycles, ATVs, UTVs (side-by-sides), jeeps, bicycles, snow mobiles, etc.
        </Card.Title>
      </Card>
      </div>
    </Container>


    </>
  )
}

export default About
