import React, {useState} from 'react';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import '../App.css';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            console.log(user);
            navigate("/main")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
    }

    return(
        <>
        <Container className="text-black" style={{height: "100vh"}}>

          <div
            className="bg-image card shadow-1-strong img-fluid"
            style={{backgroundImage: `url("/IMG_4698.jpeg")`, maxWidth: "100%", height: "100vh", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}
          >
            <div className="card-body text-black">
              <h1 className="card-title justify-content-center"><i className="fa-solid fa-hand-peace"></i> CONVOY.IM</h1>
              <h5 className="card-text justify-content-center">
                Convoy Instant Messaging
              </h5>
              <br/>
              <div>
                <form className="mt-8 space-y-6" >
                    <div className=" space-y-6 rounded-md shadow-sm">


                        <div>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                required
                                className="form-control form-control-lg"
                                placeholder="Email address"
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div>

                        <div>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                className="form-control form-control-lg"
                                placeholder="Password"
                                onChange={(e)=>setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <br/>
                    <div>
                        <button
                            onClick={onLogin}
                            className="btn btn-primary btn-lg"
                        >
                            Login
                        </button>
                    </div>

                </form>
                <br/>
                <p className="text-sm text-white text-center">
                    No account yet?{' '}
                    <NavLink to="/signup" className="underline text-tertiary">
                        Sign up
                    </NavLink>
                </p>

            </div>
          </div>
        </div>
      </Container>
      </>
    )
}

export default Login
