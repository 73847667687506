import React from 'react';
import Home from './pages/Home';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Main from './pages/Main';
import About from './pages/About';
import Map from './pages/Map';
import Adverts from './pages/Adverts';
import {Routes, Route} from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';


function App() {

  return (
    <Router>
      <div className="App">
        <section>
          <div>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/main" element={<Main/>}/>
              <Route path="/signup" element={<Signup/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/map" element={<Map/>}/>
              <Route path="/Adverts" element={<Adverts/>}/>
            </Routes>
          </div>
        </section>

      </div>
    </Router>
  );
}

export default App;
